:root {
    --lightpurple: #351a3a;
    --darkpurple: #2c1530;
    --lightsalmon: #f2706f;
    --darksalmon: #ed495d;
    --lightbeige: #f9f3db;
    --white: #ffffff;
}

body::-webkit-scrollbar { 
    width: 2ex;
}

body::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-color: var(--lightpurple);
    border-radius: 1ex;
    width: 1ex;
    background-clip: padding-box;
}

body{
    position: relative;
    min-height: 100vh;
    overflow: overlay;
}

#header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 40px;
    background-color: #54253f;
    z-index: 1000;
}

#headerLogo{
    position: absolute;
    top: 0;
    bottom: 0;
    height: 52%;
    width: auto;
    left: 0;
    right: 0;
    margin: auto;
    padding: 2ex;
    cursor: pointer;
}

#menuButton{
    width: 40px;
    margin-left: 6px;
}

#menuButton div{
    width: 35px;
    height: 5px;
    background-color: #f7efd8;
    margin: 6px 0;
}

#menu{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
}

.menu-icon{
    margin-right: 0.5ex;
    fill: var(--lightbeige)
}

#menu:not([enabled="true"]){
    pointer-events: none;
}

#menuBg{
    position: absolute;
    background-color: #000000bb;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    opacity: 0;
}

#menu[enabled="true"] #menuBg{
    opacity: 1;
}

#menuContent{
    position: absolute;
    background-color: #54253f;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    padding: 10vmin;
    transition: transform 0.5s ease;
    transform: translate3d(0,-100%,0);
}

#menu[enabled="true"] #menuContent{
    transform: translate3d(0,0,0);
}

#menu .element {
    color: var(--lightbeige);
    margin: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    transition: transform 0.5s ease, opacity 1s ease;
    transform: translate3d(0,-300%,0);
    opacity: 0;
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
}

#menu .element span{
    margin-right: 0.5ex;
}

#menu[enabled="true"] .element{
    transform: translate3d(0,0,0);
    opacity: 1;
}

.section{
    margin-top: 40px;
    display: none;
}

.section[visible="true"]{
    display: block;
}

.section#contacto[visible="true"]{
    display: flex;
}

div#centering{
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100vw;
    /* position: relative; */
    /* padding-top: 50vh; */ 
}

div#textwrapper{
    /* margin-top: 50vh; */
    /* margin-bottom: -50vh; */
    /* transform: translate(0, -25%); */
    /* height: 100vh; */
}

div#textwrapper div#compra, #compra{
    font-size: 5.56vh;
    color: #fbf4b9;
    background-color:#6f62d1;
    /* margin-top: 2.5ex; */
    /* width: 30vw; */
    /* background-color: rgba(255,255,255,0.35); */
    width: fit-content;
    /* margin: 1vh auto; */
    margin: 10px;
    padding: 0.4vh 1.6vh;
    border-radius: 2vh;
    border-style: solid;
    border-width: 0.1vh;
    border-width: 0;
    padding: 2vh 6vh;

    font-family: Hubot Sans;
    font-weight: 500;
    font-stretch: 125%;
    font-size: 2vh;
    letter-spacing: 0.1vh;
    filter: drop-shadow(0.4vh 0.4vh 0 #54253f);

    /* margin-top: 5vh; */

}


.redLabel{
    background-color: #ff505d;
    background-color: #b05050;
    border: solid #54253f 0.2vh;
    box-shadow: #54253f 0.4vh 0.4vh;
    color: #fbf4b9;
    font-family: Hubot Sans;
    font-weight: 700;
    font-stretch: 125%;
    letter-spacing: 0.1vh;
    margin: 2vh;
    padding: 0.5vh 7vh;
    display: inline-block;
    font-size: 2.5vh;
}

.artistaLabel{
    color: #fbf4b9;
    color: #b05050;
    color: #54253f;
    font-family: Hubot Sans;
    font-weight: 900;
    font-stretch: 125%;
    /* letter-spacing: 0.1ex; */
    margin: 0.5ex 0;
    margin: 0.5vh auto;
    font-size: xx-large;
    padding: 0.5ex 7ex;
    padding: 0.5vh 0;
    /* display: block; */
    width: 100%;
    font-size: 3vh;
    max-width: 70vw;
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #54253f;*/
}

.artistaLabel.podcastLive{
    margin-top: 0; 
    margin-bottom: -0.8vh; 
    font-size:1.5vh; 
    font-weight: 400;
    font-stretch: 100%;
    font-size:60%; 
    font-size:2.4vmin; 


}








.logosLabel{
    /* background-color: #ff505d;
    border: solid #54253f 2px;
    box-shadow: #54253f 4px 4px; */
    color: #452942;
    font-family: Hubot Sans;
    font-weight: 700;
    font-stretch: 125%;
    letter-spacing: 0.1vh;
    margin: 2vh auto;
    padding: 0.5vh 7vh;
    font-size: 1vh;
    margin-bottom: 0;
    margin-top: 5vh;
    /* display: inline-block; */
}

.spotifyEmbed{
    /* padding: 5vh; */
    margin:5vh auto;
    max-width: 50vh;
    /* margin: 5ex auto; */
    width: 50vh;
    max-width: 80vw;
}

.spotifyEmbed iframe{
    border: solid #54253f 0.2vh;
    box-shadow: #54253f 0.4vh 0.4vh;
    background-color: #54253f;
    /* display: none; */
}

.spotifyEmbed iframe body{
    color: red; /*to change styles inside iframe, use javascript*/
    /* border: solid #54253f 2px;
    box-shadow: #54253f 4px 4px;
    background-color: #54253f; */
}


img.footerLogo{
    height: 5vh;
    width: auto;
    margin: 0.5vh 1.5vh;
}

.footerLogo{
    width: 200px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
}

#footerSpacer{
    /* overflow: hidden; */
    
}

#footer{
    /* background-image: url("footer_bg.png"); */
    /* width: 100vw; */
    /* background-repeat: repeat; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

#footer svg.logo *{
    /* fill:#452942 !important; */
    /* filter: drop-shadow(50% 50% 0 #54253f); */

}
#footer svg.logo{
    filter: drop-shadow(0.4vh 0.4vh 0 #54253f);
}

@supports(padding:max(0px)) {
    body, header, footer {
        padding-top: min(0vmin, env(safe-area-inset-top));
        padding-left: min(0vmin, env(safe-area-inset-left));
        padding-right: min(0vmin, env(safe-area-inset-right));
    }
}

div#stickers{
    /* background-image: url("Background_Stickers_Only.png");
    background-image: url("Background_Stickers_centered.png"); */
    background-image: url("Background_Stickers_centered_v2.png");
    /* background-image: url("1080x1350_Stickers_centered.png"); */
    /* width: 100vw;
    height: 100vh; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /* transform: scale(1.33) translate(0.3%, 3.3%); */
    transform: scale(2);
    /* opacity: 0; */
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

@media (min-aspect-ratio: 6/5) {
/* @media (min-width: 600px) { phone landscape and above */
    div#stickers_arriba, div#stickers_abajo {
        /* opacity: 0; */
        display: none;
    }
    div#stickers {
        /* opacity: 1; */
        display: block;
    }
}


@media (min-width: 600px) { /* phone landscape and above */

    div#textwrapper #vid, div#textwrapper #festival{
        display: inline;
        /* font-size: 20vh !important; */
        font-size: 12vw !important;
    }
    div#textwrapper #fecha{
        font-size: 4.8vw !important;
        margin-top: -0.8ex !important;
    }
    div#textwrapper #festival{
        /* margin-left: 20pt; */
    }
    div#textwrapper{
        width: 100vw;
        /* aspect-ratio: 2160 / 1350; */
        /* max-height: 100vh; */
        /* max-width: 160vh; */
        /* background-color: red; */
        /* margin: auto;
        position: fixed;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%) */
    }

    .spotifyEmbed{
        width: 72vw;
    }

}

div#stickers_arriba{
    background-image: url("1080x1350_Stickers_arriba_v2.png");
    /* width: 100vw; */
    height: 50vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    transform-origin: top;
    transform: scale(2);
    position: fixed;
    top: 0;
    z-index: -1;

    left: 0;
    right: 0;
}

div#stickers_abajo{
    background-image: url("1080x1350_Stickers_abajo_v2.png");
    /* width: 100vw; */
    height: 50vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    transform-origin: bottom;
    transform: scale(2);
    position: fixed;
    top: 50vh;
    z-index: -1;

    left: 0;
    right: 0;

}

@font-face {
    font-family: 'Hubot Sans';
    src:
      url('Hubot-Sans.woff2') format('woff2 supports variations'),
      url('Hubot-Sans.woff2') format('woff2-variations');
    font-weight: 300;
    font-stretch: 100%;
}

@font-face {
    font-family: 'Hubot Sans';
    src:
      url('Hubot-Sans.woff2') format('woff2 supports variations'),
      url('Hubot-Sans.woff2') format('woff2-variations');
    font-weight: 400;
    font-stretch: 100%;
}

@font-face {
    font-family: 'Hubot Sans';
    src:
      url('Hubot-Sans.woff2') format('woff2 supports variations'),
      url('Hubot-Sans.woff2') format('woff2-variations');
    font-weight: 500;
    font-stretch: 75%;
}
@font-face {
    font-family: 'Hubot Sans';
    src:
      url('Hubot-Sans.woff2') format('woff2 supports variations'),
      url('Hubot-Sans.woff2') format('woff2-variations');
    font-weight: 700;
    font-stretch: 75%;
}
@font-face {
    font-family: 'Hubot Sans';
    src:
      url('Hubot-Sans.woff2') format('woff2 supports variations'),
      url('Hubot-Sans.woff2') format('woff2-variations');
    font-weight: 900;
    font-stretch: 125%;
}
@font-face {
    font-family: 'Hubot Sans';
    src:
      url('Hubot-Sans.woff2') format('woff2 supports variations'),
      url('Hubot-Sans.woff2') format('woff2-variations');
    font-weight: 700;
    font-stretch: 125%;
}



div#textwrapper{
    font-family: Hubot Sans;
    font-size: 10vmin;
    z-index: 10;
    color: #452942;
    /* position: fixed;
    top: 50%;
    left:50%; */
    /* transform:translate(-50%, -50%); */
    /* width: 100vw; */

    /* background-color: red; */
}

div#textwrapper #vid{
    font-family: Hubot Sans;
    font-weight: 900;
    font-stretch: 125%;
    /* font-size: 65.49pt; */
    font-size: 26.196vw;
    letter-spacing: 0.025em;

    /* display: inline; */
}

div#textwrapper #festival{
    font-family: Hubot Sans;
    font-weight: 700;
    font-stretch: 75%;
    /* font-size: 44.34pt; */
    font-size: 17.736vw;
    /* display: inline; */
    /* margin-top: -14pt; */
    margin-top: -5.6vw;
}

div#textwrapper #fecha{
    font-family: Hubot Sans;
    font-weight: 700;
    font-stretch: 75%;
    /* font-size: 13.9pt; */
    font-size: 5.56vw;

    letter-spacing: -0.010em;
    /* margin-top: -3pt; */
    margin-top: -1.2vw;
    /* display: inline; */
}

div#textwrapper #ventaEntradas{
    font-family: Hubot Sans;
    font-weight: 900;
    font-stretch: 125%;
    /* font-size: 13.9pt; */
    font-size: 2.5vmax;
    /* font-size: 2vh; */
    line-height: 92%;
    letter-spacing: -0.010em;
    /* margin-top: -3pt; */
    /* margin-top: 7vw;
    margin-bottom: 1vw; */
    margin-top: 2ex;
    margin-bottom: 0ex;
    /* display: inline; */
    color:#fe5a6e;
    color: #b05050;
}

.precioContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    /* background-color: red; */
    width: fit-content;
    margin: auto;
    max-width: 70vw;
    /* padding: 10px; */
}

#precio{
    font-family: Hubot Sans;
    font-weight: 900;
    font-stretch: 125%;
    font-size: 60px;
    color:#6f62d1;
    color:#fe5a6e;
    position: relative;
    /* text-shadow: 1px 1px #54253f; */
    width: min-content;
    color:#f7efd8;
    color:#54253f;
    /* margin: auto; */
    margin: 0 10px;
}

#promo{
    font-size: 10px;
    font-weight: 500;
    color:#fe5a6e;
    text-align: left;
    color:#54253f;
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 3px 15px;
    border-radius: 100px;
    border: solid 1px;
    background-color: #f7efd8;
    filter: drop-shadow(0.2vh 0.2vh 0 #54253f);
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
}

div#textwrapper #compra{
    font-size: 5.56vw;
    /* margin-top: 2.5ex; */
    /* width: 30vw; */
    background-color: rgba(255,255,255,0.35);
    width: fit-content;
    margin: 1ex auto;
    padding: 0.4ex 1.6ex;
    border-radius: 1ex;
    border-style: solid;
    border-width: 0.1ex;
}

#bg{
    background-color: #f7efd8;
    /* height: 100vh;
    width: 100vw; */
    position: absolute;
    top: -1000px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    clip-path: polygon(0% -100%, 100% -100%, 100% 100%, 0% 100%);
}

body{
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

    /* background-image: url("background.png"); */
    /* background-image: url("Background_Clear.jpg"); */
    /* background-image: url("Background_Stickers.jpg"); */
    background-size: cover;
    /* font-family: Impact, "Extenda", sans-serif; */
    /* font-family: 'Roboto Condensed', sans-serif; */
    font-family: 'Roboto Flex', sans-serif;
    margin:0;
    padding:0;
    /* display: flex; */
    /* align-items: center; */
    align-items: start;
    justify-content: center;
    /* height: 100vh; */
    flex-wrap: wrap;
    /* row-gap: 0;  */
    /* align-content: center; */
    /* fbglow: hidden; */
    /* flex-direction: column; */
    text-align: center;
}

a{
    text-decoration: none;
    /* opacity: 0; */
    /* transition: opacity 1s;
    padding: 10vmin;
    margin: -5vmin 5vmin; */


}

svg.logo{
  	overflow: visible;
    margin: 2vmin 5vmin;
    font-size: 5.56vw;
    /* margin-top: 2.5ex; */
    /* width: 30vw; */
    fill: rgba(255,255,255,0.35);
    /* width: fit-content; */
    /* margin: 1ex auto; */
    /* padding: 0.4ex 1.6ex; */
    /* border-radius: 1ex; */
    /* border-style: solid; */
    /* border-width: 0.1ex; */
    /* stroke-width: 0.05ex; */
    /* stroke-width: 0.05ex; */
    stroke-width: 0.015ex;
    /* stroke: #000000; */
    stroke: #452942;
    stroke: none;

    /* opacity: 1;
    transition: opacity 1s; */

    width: 10vmin;
    height: 10vmin;

    /* box-sizing:border-box; */
    /* filter:
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black)
    drop-shadow(1px 1px black); */

    /* padding: 10vmin;

    margin: 0 5vmin; */

    /* stroke-width: 1px; */
}

/* ARTISTAS */

/* #stickers, #stickers_arriba, #stickers_abajo{ */
.bgStickers{
    opacity: 0.5;
}

#stickers, #stickers_arriba{   
    background-color: #f7efd8;
}

.artistWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.artista{
    margin: 2vh;
    padding: 0;
    /* border: solid #54253f 0.2vh; */
    /* background-color: #54253f; */

    max-width: calc(95vw - 4vh);
    width: 50vh;
    width: 40vw;
    width: 40vmin;
}

#overlay .artista{
    width: 80vmin;
    /* pointer-events: none; */
}

#overlay .artistaImage{
    height: 80vmin;
}

#overlay .artista .artistaText a{
    color: #f7efd8;
    text-decoration: underline;
}

#overlay .artista .artistaText{
    display: block;
    transition: transform 0.3s ease-out;
    transform: translate(0, -100%);
    background-color: #54253f;
    z-index: -1;
    position: relative;
    border: solid #54253f 0.2vh;

}

.artistaImage{
    border: solid #54253f 0.2vh;
    /* width: 100%; */
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    font-size: 3vh;
  	height:40vmin;
}

.artistaName{
    position: absolute;
    bottom: 1vh;
    left:-1vh;
    background-color: #f7efd8;
    border: solid #54253f 0.2vh;
    box-shadow: #54253f 0.4vh 0.4vh;



    color: #54253f;
    font-family: Hubot Sans;
    font-weight: 900;
    font-stretch: 125%;
    /* letter-spacing: 0.1ex; */
    margin: 0.5ex 0;
    margin: 0.5vh auto;
    font-size: xx-large;
    padding: 0.5ex 7ex;
    padding: 0.5vh 0;
    /* display: block; */
    /* width: 100%; */
    /* padding: 0.5vh 5vh; */
    padding: 0.5vh 3vh;
    font-size: 3vh;
    /* max-width: 70vw; */
    max-width: 70%;
    width: min-content;

    min-width: 70%;
    min-width: 60%;
    max-width: 100%;
    font-size: 5vmin;
    padding: 0.5vmin 5vmin;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.artistaText{
    padding: 2vh;
    color: #f7efd8;
    text-align: left;
    /* height: 0; */
    display: none;
}

.artistaText br{
    margin: 1vh;
    display: block;
    content: "";
}

.artistaText .br{
    margin: 1vh;
}


#footer{
    /* background-image: url("footer_bg.png"); */
    /* width: 100vw; */
    background-color: #54253f;
    margin-bottom:0;
    /* background-repeat: repeat; */

    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2vh;
    gap: 1vh 5vh;
    margin-top: 3vh;
}

#footer .footerSection {
    margin-top: 1vmin;
    /* margin: 1vh 5vh; */
    /* padding: 2vh; */
    /* background-image: url("footer_bg.png"); */
    /* width: 100vw;
    background-color: #54253f;
    margin-bottom:0; */
    /* background-repeat: repeat; */

    /* display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;
    flex-wrap: wrap; */
}

#footer .footerSectionTitle {
    color: #f7efd8;
    font-family: Hubot Sans;
    font-weight: 400;
    font-stretch: 100%;
    /* font-stretch: 125%; */
    letter-spacing: 0.1vh;
    /* margin: 2vh auto; */
    /* padding: 0.5vh 7vh; */
    font-size: 1vh;
    /* text-align: left; */
    /* margin-bottom: 0; */
    /* margin-top: 5vh; */
}

#footer .footerSection svg.logo{
    /* max-width: 60px; */
    height: 4vh;
    width: auto;
    margin: 1.5vh 1.5vh;
}

#overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.90);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 100;

    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
}

#overlay[enabled="true"]{
    opacity: 1;
    pointer-events: initial;
    overflow: scroll;
}

.section#menores, .section#faq, .section#prensa, .section#contacto, .section#previd, .section#postvid, .section#partners{
    text-align: left;
    padding: 15vmin 10vmin;
    max-width: 1000px;
    margin: auto;
}

.section#partners .partner{
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 30px 0;
    background-color: #f7efd8;
    padding: 20px;
    border: solid #54253f 0.2vh;
    align-items: flex-start;
}

.section#partners .partner h3{
    display: none;
}

.section#partners .logo{
    height: auto;
    width: auto;
    max-width: 140px;
    max-height: 100px;
}

.section#partners img:not(.logo){
    height: auto;
    width: auto;
    width: 100%;
    max-width: 50%;
    max-width: 450px;
    box-sizing: border-box;
}

.section#partners .partner > div{
    min-width:200px;
}

.section#partners .partner > img + div{
    max-width: 50%;
    max-width: 450px;
    padding-left: 0;
    padding-top: 20px;
    box-sizing: border-box;
}

@media (min-width: 600px) { /* phone landscape and above */
    .section#partners img:not(.logo),
    .section#partners .partner > img + div {
        max-width: 50%;        
    }

    .section#partners .partner > img + div {
        padding-left: 20px;        
    }
}

.section#buses{
    text-align: left;
    max-width: 1000px;
    margin: auto;
}

.section#buses h2{
    padding: 0vmin 10vmin;
    padding-top: 15vmin 
}

p.indent{
    margin-left: 2em;
}

.section#menores a, .section#prensa a, .section#faq a, .section#contacto a{
    text-decoration: underline;
    color: #6f62d1;
}

#faq .question{
    text-decoration: bold;
    margin: 15px 0;
}

#faq .answer{
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translate3d(0,0,0);
}

#faq .question{
    transition: transform 0.5s ease;
    transform: translate3d(0,0,0);
}

#faq .question b{
    position: relative;
    z-index: 10;
    display: flex;
}

#faq .answer:not([visible="true"]){
    transition: opacity 0.5s ease, transform 0.5s ease;
    height: 0;
    opacity: 0;
    transform: translate3d(0,-50px,0);
}

#faq .question b .chevron{
    transition: transform 0.2s ease;
}

#faq .question b[expand="true"] .chevron{
    transform: rotateZ(90deg);
}

#prensa img{
    max-width: 100%;
}

#prensa p {
    overflow-wrap: break-word;
}

#prensa .noticia:not(:first-of-type){
    margin-top: 20px;
    border-top: solid 1px black;
}

iframe.gforms{
    min-width: 50%;
    margin: auto;
}

#buses iframe.gforms, #previd iframe.gforms, #postvid iframe.gforms{
    width: 100%;
}

.postvid-label{
    padding: 1ex;
}

.postvid-label[selected="true"]{
    border: 1px solid #54253f;
    border-radius: 4px;
}

.section#contacto{
    padding: 15vmin 5vmin;
    position: relative;
    flex-wrap: wrap;
    padding: 15vmin 5vmin;
}

.overlayMix{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7efd8;
    mix-blend-mode: darken;
    z-index: 100;
    pointer-events: none;
}

body{
    background-color: #54253f;
}

#mapa{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

#mapa .point
{
    position: absolute;
    top: 50%;
    left: 50%;
}

.lil-gui.root{
    max-height:30vh;
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 1001;
}

.lil-gui {
    font-size: xx-small;
}

video{
    position:absolute;
    z-index: 10;
    top: 0;
    left: 0;
    max-width: 30vmin;
    visibility: hidden;
    opacity: 0; 
    pointer-events: none;
}

.flex-card-container{
    display: flex;
    flex-wrap: wrap;
}

.flex-card{
    border: 2px solid var(--lightsalmon);
    background-color: var(--lightbeige);
    flex: 1 1 0px;
    margin: 3ex 1ex;
    border-radius: 3ex;
    position: relative;
    padding: 2.5ex 0 2ex 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    transition: transform 0.2s ease;
    transform: scale3d(1,1,1);
    min-width: 150px;
    text-align: center;
}

.flex-card:hover{
    transform: scale3d(1.05,1.05,1.05);
    z-index: 10;
}

.flex-card-content{
    margin: auto;
}

.flex-card-title {
    background-color: var(--darksalmon);
    border-radius: 1ex;
    color: var(--lightbeige);
    padding: 0.5ex 4ex;
    width: fit-content;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    transform: translate3d(0,-50%,0);
}

.section#buses > p, .section#buses .flex-card-container{
    padding: 0 10vmin;
}

canvas.webgl, #mapa .point{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#mapa .point div{
    position: absolute;
    opacity: 0;
    padding: 1ex 2ex;
    transition: opacity 0.3s ease;
    color:#fbf4b9;
    background-color: #b05050;
    font-size: 10px;
    border-radius: 10px;
    filter: drop-shadow(2px 2px 0 #54253f);
    white-space: nowrap;
    transform: translate3d(-50%, -50%, 0);
    pointer-events: none;
}

#mapa .point div.number{
    padding: 2ex;
}

#mapa.near .point .name{
    opacity: 1;
}

#mapa.far .point .number{
    opacity: 1;
}

body{
    font-family: Hubot Sans;
    font-weight: 300;
}